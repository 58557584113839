.rc-dialog-mask {
  background: rgba(0, 0, 0, 0.2);
}

.rc-dialog-wrap {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

.rc-dialog {
  width: calc(100% - 32px);
}

.rc-dialog-content {
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
  border-radius: 6px;
  /*overflow: hidden;*/
  min-height: 188px;
  padding: 0;
}

.rc-dialog-header {
  border-bottom: none;
  padding: 16px 24px;
  max-height: 56px;
}

.rc-dialog-body {
  padding: 8px 24px 24px 24px;
}

.rc-dialog-title {
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #494c5b;
}

.rc-dialog-close {
  top: 24px;
  right: 24px;
  opacity: 1;
}
