html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

body.noscroll {
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

#root {
  width: 100%;
  min-width: fit-content;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* flex: 1 1 auto; */
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  src: local("Roboto"), url(assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(assets/fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"),
    url(assets/fonts/NunitoSans-Bold.ttf) format("truetype");
  font-weight: 700;
}
